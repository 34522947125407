var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Layout } from '~/core/decorator';
import { namespace } from 'vuex-class';
import Overview from '~/components/dashboard/user/all-overview.vue';
import ProgressChart from '~/components/dashboard/user/progress-chart.vue';
import Rank from '~/components/dashboard/user/rank.vue';
var accManageModule = namespace('acc-manage');
var DashboardRecruiter = /** @class */ (function (_super) {
    __extends(DashboardRecruiter, _super);
    function DashboardRecruiter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DashboardRecruiter = __decorate([
        Layout('workspace'),
        Component({
            components: {
                Overview: Overview,
                ProgressChart: ProgressChart,
                Rank: Rank
            },
        })
    ], DashboardRecruiter);
    return DashboardRecruiter;
}(Vue));
export default DashboardRecruiter;
