var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import PieEcharts from '~/components/dashboard/user/pie-echarts.vue';
var ProgressChart = /** @class */ (function (_super) {
    __extends(ProgressChart, _super);
    function ProgressChart() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.echartsDataOne = {
            rows: [
                { name: '委案金额', value: '30' },
                { name: '回款金额', value: '70' }
            ],
            color: ['#E0B353', '#7278EE'],
            text: '30'
        };
        _this.echartsDataTwo = {
            rows: [
                { name: '委案金额', value: '30' },
                { name: '回款金额', value: '70' }
            ],
            color: ['#54A0EE', '#4CC99B'],
            text: '30'
        };
        _this.echartsDataThree = {
            rows: [
                { name: '委案金额', value: '30' },
                { name: '回款金额', value: '70' }
            ],
            color: ['#E0B353', '#7278EE'],
            text: '30'
        };
        _this.echartsDataFour = {
            rows: [
                { name: '委案金额', value: '30' },
                { name: '回款金额', value: '70' }
            ],
            color: ['#54A0EE', '#4CC99B'],
            text: '30'
        };
        return _this;
    }
    ProgressChart = __decorate([
        Component({
            components: {
                PieEcharts: PieEcharts
            },
        })
    ], ProgressChart);
    return ProgressChart;
}(Vue));
export default ProgressChart;
